import { FC, useContext, useState } from "react";
import Paragraph from "@/Components/UI/Paragraph/Paragraph";
import {
  CancelIcon,
  DeleteBinIcon,
  EditIcon,
  SaveIcon,
} from "@/assets/svg/svg";
import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";
import { UserContext } from "@/context/UserContext/UserContext";
import deleteFavoriteGroupsApi from "@/Components/VideoPlayer/UserSetting/FavoritePlayList/utils/api/deleteFavoriteGroupApi";
import ParagraphTypeEnum from "@/utils/enums/paragraph-type.enum";
import editFavoriteGroupNameApi from "@/Components/VideoPlayer/UserSetting/FavoritePlayList/utils/api/editFavoriteGroupNameApi";
import SearchInput from "@/Components/UI/SearchInput/SearchInput";
import toast from "react-hot-toast";
import { useFavoriteGroupsContext } from "@/context/FavoriteGroupsContext/utils/hooks/useFavoriteGroupsContext";
import { FavoriteGroupsActionEnum } from "@/utils/enums/favorite-groups-action.enum";

interface GroupItemProps {
  group: GroupsInterface;
}

const GroupItem: FC<GroupItemProps> = ({ group }) => {
  const { user } = useContext(UserContext)!;
  const { dispatch } = useFavoriteGroupsContext();
  const [isEditing, setIsEditing] = useState(false);
  const [groupName, setGroupName] = useState(group.label);

  const deleteGroup = async () => {
    await deleteFavoriteGroupsApi(user, group);
    dispatch({ type: FavoriteGroupsActionEnum.REMOVE_GROUP, payload: group });
  };

  const startEditing = () => {
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setGroupName(group.label);
  };

  const saveGroupName = async () => {
    if (groupName !== group.label) {
      const updatedGroup = await editFavoriteGroupNameApi(user, {
        ...group,
        label: groupName,
        value: groupName,
      });
      if (updatedGroup?.data.success) {
        dispatch({
          type: FavoriteGroupsActionEnum.UPDATE_GROUP,
          payload: updatedGroup.data.group,
        });
        toast.success("Group name updated successfully");
      } else {
        toast.error("Error updating group name");
      }
    }
    setIsEditing(false);
  };

  return (
    <div className="flex justify-between mt-2 mb-2">
      {isEditing ? (
        <div className="flex justify-between gap-4">
          <SearchInput
            searchTerm={groupName}
            handleChange={(e) => setGroupName(e.target.value)}
            maxLength={30}
          />
          <div className="flex gap-2 justify-start items-center ">
            <span onClick={saveGroupName}>
              <SaveIcon />
            </span>
            <span onClick={cancelEditing}>
              <CancelIcon />
            </span>
          </div>
        </div>
      ) : (
        <>
          <Paragraph type={ParagraphTypeEnum.p1_Small}>{group.label}</Paragraph>
          <div className="flex gap-2 justify-start">
            <span onClick={startEditing}>
              <EditIcon />
            </span>
            <span onClick={deleteGroup}>
              <DeleteBinIcon />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default GroupItem;
