import React, { FC, useContext, useState } from "react";
import { CloseIcon, SettingsIcon } from "@/assets/svg/svg";
import { ThemeContext } from "@/context/ThemeContext/ThemeContext";
import CreateGroup from "./CreateGroup/CreateGroup";
import { GroupsInterface } from "@/context/FavoriteContext/FavoriteContext";
import GroupItem from "./GroupItem/GroupItem";
import Heading from "@/Components/UI/Heading/Heading";
import HeadingTypeEnum from "@/utils/enums/heading-type.enum";
import generateRandomNineDigitNumber from "@/utils/helpers/generateRandomNineDigitNumber";
import setFavoriteGroupsApi from "../../../utils/api/setFavoriteGroupsApi";
import toast from "react-hot-toast";
import { UserContext } from "@/context/UserContext/UserContext";
import { useFavoriteGroupsContext } from "@/context/FavoriteGroupsContext/utils/hooks/useFavoriteGroupsContext";
import { FavoriteGroupsActionEnum } from "@/utils/enums/favorite-groups-action.enum";

interface FavoriteSettingsProps {
  favoriteGroups: GroupsInterface[] | null;
  className?: string;
}

const FavoriteSettings: FC<FavoriteSettingsProps> = ({
  favoriteGroups,
  className,
}) => {
  const { mode } = useContext(ThemeContext)!;
  const { user } = useContext(UserContext)!;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { dispatch } = useFavoriteGroupsContext();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleFormClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const createGroup = async (groupName: string) => {
    const newGroup = {
      id: generateRandomNineDigitNumber(),
      value: groupName,
      label: groupName,
      videos: [],
    };

    const createdGroup = await setFavoriteGroupsApi(user, newGroup);

    if (createdGroup?.success) {
      toast.success("Group created successfully");
      dispatch({ type: FavoriteGroupsActionEnum.ADD_GROUP, payload: newGroup });
    } else {
      toast.error("Error creating group");
    }
  };

  return (
    <div>
      <span className={className} onClick={openModal}>
        <SettingsIcon />
      </span>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-background-bgDark300 bg-opacity-90 flex justify-center items-center z-50"
          onClick={() => closeModal()}
        >
          <div
            className={`${
              mode === "dark"
                ? "bg-background-bgDark100"
                : "bg-background-bgLight100"
            } relative p-6 rounded-md shadow-lg flex flex-col gap-6`}
            onClick={handleFormClick}
          >
            <div className="mt-4">
              <div>
                <CreateGroup onCreateGroup={createGroup} />
              </div>
              {favoriteGroups && favoriteGroups?.length > 1 && (
                <>
                  <Heading type={HeadingTypeEnum.h2_Small}>My groups:</Heading>
                  <div>
                    {favoriteGroups
                      .filter((group) => group.id !== 1 && group.id !== 2)
                      .map((group) => (
                        <React.Fragment key={group.id}>
                          <GroupItem group={group} />
                        </React.Fragment>
                      ))}
                  </div>
                </>
              )}
            </div>

            <span onClick={() => closeModal()}>
              <CloseIcon className={"absolute top-2 right-2 cursor-pointer"} />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FavoriteSettings;
